<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Host Details</h5>
            </div>
            <div class="card">
                <div class="p-d-flex p-ai-center p-flex-column p-flex-lg-row p-mb-1">
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="emp_name" :options="adminList" @change="getHostDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select admin"
                            :style="{ minWidth: '200px' }" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <TabView v-if="userdatadisplayflag">
        <TabPanel header="Host Details">
            <div class="card">
                <DataTable :value="EmpDetailsList" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="EmpTotalRecords" :loading="loading" @page="onPageChange" class="p-datatable-users"
                    data-key="ad1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>

                    <Column header="Host Name" headerStyle="width: 30%">
                        <template #body="{ data }">
                            <div class="p-d-flex p-ai-center">
                                <div class="p-mr-3">
                                    <span v-if="data.ac18 != null && data.ac18 != ''">
                                        <img :src="'https://storage.googleapis.com/' + storage_path + '/hosts/Logo/' + data.ac18"
                                            height="50" width="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                    <span v-if="!data.ac18">
                                        <img :src="'/assets/layout/images/default_hotel.jpeg'" height="50" width="50"
                                            style="vertical-align: middle; border-radius: 75px" />
                                    </span>
                                </div>
                                <div>
                                    <div class="p-text-capitalize">
                                        <span>{{ data.ac3 ? data.ac3 : "-" }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Contact Person" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <span class="p-text-capitalize">{{ data.ac6 ? data.ac6 : "-" }}</span>
                        </template>
                    </Column>

                    <Column header="Contact Number" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <span>{{ data.ac7 ? data.ac7 : "-" }}</span>
                        </template>
                    </Column>
                    <Column header="Address" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <span class="p-text-capitalize">{{ data.ac21 ? data.ac21 : "-" }}</span>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <Button icon="pi pi-user" v-tooltip.left="'Login As Host'" severity="info"
                                class="p-button-rounded p-button-info" @click="loginAsHostDialogOpen()" />
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-ml-1"
                                @click="editHostDetailsRedirect(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
    </TabView>
</template>
<script>
import ApiService from '../service/ApiService';
import router from '@/router';

export default {
    data() {
        return {
            adminList: [],
            hostList: [],
            branchList: [],
            page_no: 0,
            EmpTotalRecords: 0,
            loading: false,
            EmpDetailsList: [],
            emp_name: '',
            host_name: '',
            branch_name: '',
            userdatadisplayflag: false,
            admin_id: '',
            host_id: '',
            admin_list_status: false,
            host_list_status: false,
            branch_list_status: false,
            itemsPerPage: 30
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.storage_path = JSON.parse(localStorage.masterBucketName);
        if (this.$store.state.adminId != null) {
            this.admin_id = this.$store.state.adminId;
            let adminId = this.$store.state.adminId;
            this.ApiService.getAdminListOnly().then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data.records;
                    let emp_name_id = data.data.records.filter(function (item) {
                        return item.value == adminId;
                    });
                    if (emp_name_id.length > 0) {
                        this.emp_name = emp_name_id[0];
                    }
                    this.getHostDetails();
                } else {
                    this.adminList = null;
                }
            });
        } else {
            this.getAdminListOnly();
        }
    },

    methods: {
        editHostDetailsRedirect(data) {
            router.push({
                name: "hostDetails",
                params: { hostId: data.ac1, clientId: this.admin_id },
            });
            this.$store.dispatch("updateHostRedirectStatus", this.admin_id);
        },
        getAdminListOnly(e) {
            this.ApiService.getAdminListOnly(e).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data.records;
                    if (data.data.records.length == 1) {
                        this.getHostDetails({ value: data.data.records[0] });
                        this.emp_name = data.data.records[0];
                    }
                } else {
                    this.adminList = null;
                }
            });
        },
        getHostDetails(e) {
            this.userdatadisplayflag = true;
            this.loading = true;
            if (e) {
                this.admin_id = e.value.value;
            }
            this.ApiService.getHostDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage }).then((data) => {
                if (data.success === true) {
                    this.EmpDetailsList = data.data.result;
                    this.EmpTotalRecords = data.data.count;
                    this.loading = false;
                    this.admin_list_status = true;
                    this.host_name = '';
                    this.branch_name = '';
                    if (e) {
                        this.getHostListOnly(e);
                    }
                }
            });
        },
        onPageChange(e) {
            this.loading = true;
            if (this.admin_list_status) {
                this.page_no = e.page;
                this.getHostDetails();
            }
        },
        getHostListOnly(e) {
            this.ApiService.getHostListOnly({ clientId: e.value.value }).then((data) => {
                if (data.status == 200) {
                    this.hostList = data.data.records;
                } else {
                    this.hostList = null;
                }
            });
        },
    },
};
</script>
<style scoped lang="scss"></style>
